import axios from 'axios';
import store from '@/store'
import router from '@/router'

// 设置基础URL地址
const baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL + '/' : process.env.VUE_APP_BASE_URL + '/'; // 根据开发/生产环境配置不同的API URL前缀

// 创建axios实例
const instance = axios.create({
    baseURL: baseUrl,
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 注入token
    if (store.state.token) {
        config.headers['X-Access-Token'] = store.state.token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, async function (error) {
    // 对响应错误做点什么
    if (error.response.status == 500) {
        await store.dispatch('logout') // 调用action 退出登录
        // 主动跳转登录页
        router.push('/login')
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export default instance