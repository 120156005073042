import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '../utils/auth'

Vue.use(VueRouter)

const routes = [{
  path: '/login',
  name: 'login',
  component: () => import('../views/login.vue'),
  hidden: true
},{
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue')
},{
  path: '/home',
  name: 'home',
  component: () => import('../views/home.vue')
}]
const createRouter = () => new VueRouter({
  mode: 'hash',
  routes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

router.beforeEach((to, from, next) => {
  if (to.path == '/login') return next()
  const token = getToken()

  if(!token) return next('/login')
  next()
})

export default router