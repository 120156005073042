import Vue from 'vue'
import Vuex from 'vuex'
import { resetRouter } from '../router'

Vue.use(Vuex)

import { getToken, setToken, removeToken } from '../utils/auth'
export default new Vuex.Store({
  state: {
    token: getToken(), // 从缓存中读取初始值
  },
  mutations: {
    setTokens(state, token) {
      state.token = token
      // 同步到缓存
      setToken(token)
    },
    removeToken(state) {
      // 删除Vuex的token
      state.token = null
      removeToken()
    }
  },
  actions: {
    // context上下文，传入参数
    // 退出登录
    logout(context) {
      context.commit('removeToken') // 删除token
      // 调用重置路由的方法
      resetRouter()
    }
  },
  modules: {

  }
})
